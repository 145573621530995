import { TFunction } from 'i18next';

import { Region } from '@eon-home/react-library';

import { getRegion } from './i18n';
import { handleError } from './debug';
import { PUBLIC_ROUTES } from '@tools/enums';
import { ComponentAction } from '@tools/types';
import { toLocalizedCurrency } from './localization-helpers';
import { ComponentActionTypes } from '@store/enums';
import { FieldErrors } from 'react-hook-form';

export const toJSON = <T>(dataAsString: string): T | undefined => {
    try {
        return JSON.parse(JSON.parse(dataAsString)) as T;
    } catch (err) {
        return undefined;
    }
};

// prettier-ignore
export const isNumber = (value: unknown): boolean => typeof value === 'number' && !isNaN(value) && isFinite(value);

export const isPublicRoute = (): boolean => {
    return PUBLIC_ROUTES.includes(window.location.pathname as any);
};

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        window.location.hostname === '[::1]' ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

export const isSafariBrowser = () => {
    const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
    const isSafari = navigator.userAgent.indexOf('Safari') > -1;

    if (!isSafari) {
        return false;
    }

    return !isChrome;
};

export const copyToClipboard = async (data: string): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(data);

        return Promise.resolve(true);
    } catch (e) {
        await handleError(e, 'navigator.clipboard not supported: ');

        return Promise.reject(false);
    }
};

export const decorateText = (text: string, tag: string = 'strong') => {
    const selfClosingTag = [
        'br',
        'hr',
        'col',
        'img',
        'wbr',
        'area',
        'base',
        'link',
        'meta',
        'embed',
        'input',
        'param',
        'track',
        'keygen',
        'source',
        'command',
        'menuitem',
    ];

    return text.replace(
        /\*([^*]+)\*/g,
        selfClosingTag.includes(tag) ? `<${tag}/>` : `<${tag}>$1</${tag}>`,
    );
};

export const getCSSCustomProp = <T extends string>(name: string): T => {
    return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--' + name)
        .trim() as T;
};

export const downloadFile = (url: string, filename: string): void => {
    const link = document.createElement('a');

    link.href = url;
    link.download = filename;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

    if (newWindow) {
        newWindow.opener = null;
    }
};

export const componentReducer = <S>(
    state: S,
    action: ComponentAction<S>,
): S => {
    switch (action.type) {
        case ComponentActionTypes.SET_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const composeClassName = (
    main: string,
    modifiers: string[],
    optional: Array<string | undefined> = [],
): string =>
    [
        main,
        ...modifiers
            .filter(Boolean)
            .map((modifier: string) => `${main}--${modifier}`),
        ...optional,
    ]
        .filter(Boolean)
        .join(' ');

export const arrayChunks = <T>(array: T[], chunk_size: number): T[][] =>
    Array(Math.ceil(array.length / chunk_size))
        .fill('')
        .map((_, index) => index * chunk_size)
        .map((begin) => array.slice(begin, begin + chunk_size));

// prettier-ignore
export const toBase64 = (value: string): string => btoa(unescape(encodeURIComponent(value)));

export const calculatePvRatedCapacity = (
    pvGeneration: number | undefined,
    pvPeakPower: number | undefined,
) => {
    if (
        typeof pvGeneration === 'undefined' ||
        typeof pvPeakPower === 'undefined'
    ) {
        return 0;
    }

    return (pvGeneration / pvPeakPower) * 100;
};

// prettier-ignore
export const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

export const toLocalCurrency = (value?: number) => {
    const region = getRegion();
    // Hungary doesn't have a currency subunit despite what Wikipedia says
    const division = region === Region.HU ? 1 : 100;

    return toLocalizedCurrency(!!value ? value / division : 0);
};

export const convertSecondsInHoursAndMinutes = (
    seconds: number,
    format: 'h' | 'h|min',
    t: TFunction,
): string => {
    const durationInMinutes = Math.round(seconds / 60);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.round(durationInMinutes % 60);

    return format === 'h'
        ? t('{{hours}}:{{minutesPadding}}{{minutes}}h', {
              hours,
              minutes,
              minutesPadding: minutes >= 10 ? '' : 0,
          })
        : t('{{hours}}h {{minutes}}m', {
              hours,
              minutes,
          });
};

export const convertMilitaryTimeToRegularTimeString = (value?: string) =>
    !value ? '' : value.slice(0, 2) + ':' + value.slice(2, 4);

export const redirectToExternal = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
    return null;
};

export const errorMessage = (t: TFunction): Record<string, string> => ({
    GridBoxAlreadyPaired: t(
        'This GridBox is already paired. Please check the device or contact support if needed.',
    ),
    InvalidStartCode: t(
        'The start code is invalid. Please ensure the code meets the required format.',
    ),
    ConfigurationNotAdded: t(
        'Failed to add configuration. Please verify your input or contact support.',
    ),
    CustomerNotFound: t(
        'Customer not found. Please verify the customer ID or contact support.',
    ),
    InvalidSerialNumber: t(
        'The provided serial number is invalid. Please check and try again.',
    ),
    InvalidSiteId: t(
        'The provided site ID is invalid. Please verify and try again.',
    ),
    VendorNotFound: t(
        'Vendor not found. Please ensure the vendor ID is correct or contact support.',
    ),
    UnsupportedDeviceType: t(
        'The specified device type is not supported. Please review the device type or contact support.',
    ),
    OverwriteGatewayError: t(
        'Cannot overwrite existing gateway settings. Please review the configuration or contact support.',
    ),
    ConfigurationNotDeleted: t(
        'Failed to delete configuration. Please try again or contact support.',
    ),
    ConfigurationNotUpdated: t(
        'Configuration update failed. Please review your data or contact support.',
    ),
    InvalidCustomerNumber: t(
        'The customer number is invalid. Please verify and try again.',
    ),
    OverwriteInverterKindError: t(
        'Cannot overwrite the inverter kind. Please review your configuration or contact support.',
    ),
    UnsupportedConfiguration: t(
        'The configuration is not supported. Please check your input or contact support.',
    ),
    UnknownError: t(
        'An unknown error occurred. Please try again or contact support.',
    ),
});

export const hasErrors = (errors: FieldErrors) => {
    return Object.keys(errors).length > 0;
};
